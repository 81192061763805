import ActionTypes from 'constants/ActionTypes';
import update from 'immutability-helper';
import concat from 'ramda/src/concat';
import includes from 'ramda/src/includes';
import { NewTrackingStep } from 'components/newTracking/step/NewTrackingStep';

const initialState = {
    keywordsInLimit: [],
    keywordsNotInLimit: [],
    lastDateInHistory: null,
    filteredCount: {
        duplicates: 0,
        tooShort: 0,
    },
    selectedListIds: [],
    domain: '',
    step: NewTrackingStep.Domain,
    settings: {
        serpPositionFrom: 0,
        serpPositionTo: null,
        searchCountFrom: 0,
        searchCountTo: null,
        hideSelected: true,
    },
    quickSettings: {
        search: '',
    },
    trackingConfig: {
        place_id: null,
        ludocid: null,
        name: null,
        address: null,
        forced_place_id: null,
    },
    data: null,
};

const newTrackingReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.UI_NEW_TRACKING_DOMAIN_SET: {
            return update(state, {
                domain: { $set: action.payload },
            });
        }
        case ActionTypes.UI_NEW_TRACKING_SELECTED_LIST_SET: {
            const { listId, selected } = action.payload;

            if (selected === true) {
                return update(state, {
                    selectedListIds: {
                        $apply: ids => {
                            if (includes(ids, listId)) {
                                return ids;
                            } else {
                                return concat(ids, [listId]);
                            }
                        },
                    },
                });
            } else {
                return update(state, {
                    selectedListIds: {
                        $apply: ids => ids.filter(id => id !== listId),
                    },
                });
            }
        }
        case ActionTypes.UI_NEW_TRACKING_SELECTED_LISTS_RESET: {
            return update(state, {
                selectedListIds: { $set: [] },
                lastDateInHistory: { $set: state?.lastDateInHistory },
            });
        }
        case ActionTypes.UI_NEW_TRACKING_KEYWORDS_SET: {
            return update(state, {
                keywordsInLimit: { $set: action.payload.keywordsInLimit },
                keywordsNotInLimit: { $set: action.payload.keywordsNotInLimit },
                lastDateInHistory: { $set: action.payload.lastDateInHistory },
            });
        }
        case ActionTypes.UI_NEW_TRACKING_KEYWORDS_FILTERED_COUNT_SET: {
            return update(state, {
                filteredCount: { $set: action.payload },
            });
        }
        case ActionTypes.UI_NEW_TRACKING_FILTER_SETTINGS_SET: {
            return update(state, {
                settings: {
                    $set: action.payload,
                },
            });
        }
        case ActionTypes.UI_NEW_TRACKING_CONFIG_DATA_SET: {
            return update(state, {
                trackingConfig: {
                    $set: action.payload,
                },
            });
        }
        case ActionTypes.UI_NEW_TRACKING_CONFIG_DATA_RESET: {
            return update(state, {
                trackingConfig: {
                    $set: {
                        place_id: null,
                        ludocid: null,
                        name: null,
                        address: null,
                        forced_place_id: null,
                    },
                },
            });
        }
        case ActionTypes.UI_NEW_TRACKING_SELECTED_FILTER_SETTINGS_SET: {
            return update(state, {
                settings: {
                    $set: {
                        ...state.settings,
                        hideSelected: action.payload,
                    },
                },
            });
        }
        case ActionTypes.UI_NEW_TRACKING_FILTER_SETTINGS_UPDATE: {
            return update(state, {
                settings: {
                    $set: {
                        ...state.settings,
                        ...action.payload,
                    },
                },
            });
        }
        case ActionTypes.UI_NEW_TRACKING_QUICK_FILTER_SETTINGS_SEARCH_SET: {
            return update(state, {
                quickSettings: {
                    search: { $set: action.payload },
                },
            });
        }
        case ActionTypes.UI_NEW_TRACKING_KEYWORDS_RESET: {
            return update(state, {
                keywordsInLimit: { $set: initialState.keywordsInLimit },
                keywordsNotInLimit: { $set: initialState.keywordsNotInLimit },
                lastDateInHistory: { $set: initialState.lastDateInHistory },
            });
        }
        case ActionTypes.UI_NEW_TRACKING_TEMPLATE_FILL: {
            return update(state, {
                domain: { $set: action.payload.domain },
                trackingConfig: { $set: action.payload.trackingConfig },
            });
        }
        case ActionTypes.DATA_NEW_TRACKING_RECEIVED: {
            return update(initialState, {
                data: { $set: action.payload.data },
            });
        }
        case ActionTypes.UI_NEW_TRACKING_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default newTrackingReducer;
