import React, { Component } from 'react';
import { bool, func, number } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import isNil from 'ramda/src/isNil';

import DateRangePicker from 'components/other/DateRangePicker';
import TimeframeService from 'services/TimeframeService';

import TimeframePresets from 'constants/TimeframePresets';
import TimeframeType from 'types/TimeframeType';

class TimeframeDropdown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            from: this.props.timeframe.from,
            to: this.props.timeframe.to,
            type: this.props.timeframe.type,
        };

        this.handlePresetButtonClick = this.handlePresetButtonClick.bind(this);
        this.handleSetTimeframeClick = this.handleSetTimeframeClick.bind(this);
        this.handleTimeframeChange = this.handleTimeframeChange.bind(this);
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (
            !this.props.timeframe.from.isSame(newProps.from) ||
            !this.props.timeframe.to.isSame(newProps.to) ||
            this.props.timeframe.type !== newProps.type
        ) {
            this.setState({
                from: newProps.timeframe.from,
                to: newProps.timeframe.to,
                type: newProps.timeframe.type,
            });
        }
    }

    handleTimeframeChange({ from, to }) {
        const translated = TimeframeService.translate({ from, to });

        this.setState({
            from: translated.from,
            to: isNil(to) ? null : translated.to,
            type: translated.type,
        });
        if (!this.props.isTimeframeModal) {
            this.props.onChange({
                from: translated.from,
                to: isNil(to) ? null : translated.to,
                type: translated.type,
                close: false,
            });
        }
    }

    handleSetTimeframeClick() {
        this.props.onClose();

        if (!isNil(this.state.from) && !isNil(this.state.to) && !isNil(this.state.type)) {
            this.props.onChange({
                from: this.state.from,
                to: this.state.to,
                type: this.state.type,
            });
        } else if (!isNil(this.state.from)) {
            // Handle only from selected case
            const translated = TimeframeService.translate({ from: this.state.from, to: this.state.from });
            this.props.onChange({
                from: translated.from,
                to: translated.to,
                type: translated.type,
            });
        }
    }

    /* eslint-disable max-len */
    handlePresetButtonClick(type) {
        if (
            this.state.type !== type &&
            TimeframeService.isPresetAvailable(this.props.currentTrackingCreatedAt, type) === true
        ) {
            const translated = TimeframeService.createFromType({
                createdAt: this.props.currentTrackingCreatedAt,
                type,
            });
            this.setState({ from: translated.from, to: translated.to, type: translated.type });
            if (!this.props.isTimeframeModal) {
                this.props.onChange({ from: translated.from, to: translated.to, type: translated.type, close: false });
            }
        }
    }
    /* eslint-enable max-len */

    /* eslint-disable react/jsx-no-bind */
    renderPresetButton(label, type) {
        return (
            <li
                className={classnames({
                    'is-active': this.state.type === type,
                })}
            >
                <button onClick={this.handlePresetButtonClick.bind(this, type)} type="button">
                    {label}
                </button>
            </li>
        );
    }
    /* eslint-enable react/jsx-no-bind */

    /* eslint-disable max-len */
    render() {
        if (this.props.visible) {
            return (
                <aside
                    className={classnames(
                        'uk-dropdown-right',
                        'uk-dropdown-align-right',
                        'uk-display-block',
                        'uk-flex-item-none',
                        {
                            'uk-dropdown': this.props.isTimeframeModal,
                            'mg-card': !this.props.isTimeframeModal,
                            'mg-margin-b-15': !this.props.isTimeframeModal,
                        },
                    )}
                    style={this.props.isTimeframeModal ? { width: '583px', top: '28px' } : {}}
                >
                    {this.props.isTimeframeModal && (
                        <header className="mg-dropdown-header mg-padding-0-15">
                            <h1 className="color-black font-16 uk-text-bold uk-margin-remove">Choose a timeframe</h1>
                            <button onClick={this.props.onClose} className="uk-visible-small mg-close" type="button">
                                <FontAwesomeIcon icon="times" aria-label="Close" />
                            </button>
                        </header>
                    )}
                    <div className="uk-flex uk-flex-wrap uk-flex-middle">
                        <ul
                            className="mg-listnav is-xsmall is-light font-12 mg-card"
                            style={{ maxHeight: 'none', overflow: 'visible' }}
                        >
                            {this.renderPresetButton('Today', TimeframePresets.TODAY)}
                            {this.renderPresetButton('This month', TimeframePresets.THIS_MONTH)}
                            {this.renderPresetButton('Last month', TimeframePresets.LAST_MONTH)}
                            {this.renderPresetButton('This week', TimeframePresets.THIS_WEEK)}
                            {this.renderPresetButton('Last week', TimeframePresets.LAST_WEEK)}
                            {this.renderPresetButton('Last 7 days', TimeframePresets.LAST_7_DAYS)}
                            {this.renderPresetButton('Last 30 days', TimeframePresets.LAST_30_DAYS)}
                            {this.renderPresetButton('Entire history', TimeframePresets.ENTIRE_HISTORY)}
                            <li />
                        </ul>
                        <div className="mg-padding-15 mg-margin-l-15 font-12 uk-clearfix uk-flex-item-auto">
                            <DateRangePicker
                                from={this.state.from}
                                onChange={this.handleTimeframeChange}
                                start={this.props.getStart()}
                                to={this.state.to}
                            />
                        </div>
                    </div>
                    {this.props.isTimeframeModal && (
                        <div className="mg-dropdown-footer mg-padding-0-30 mg-padding-15-0 uk-text-center">
                            <button
                                className="mg-btn is-blue is-xsmall uk-width-medium-1-2 uk-width-1-1"
                                onClick={this.handleSetTimeframeClick}
                                type="button"
                            >
                                <FontAwesomeIcon icon="check" aria-hidden="true" />
                                Set timeframe
                            </button>
                        </div>
                    )}
                </aside>
            );
        } else {
            return null;
        }
    }
    /* eslint-enable max-len */
}

TimeframeDropdown.propTypes = {
    currentTrackingCreatedAt: number,
    isTimeframeModal: bool.isRequired,
    onChange: func.isRequired,
    onClose: func.isRequired,
    getStart: func.isRequired,
    timeframe: TimeframeType.isRequired,
    visible: bool.isRequired,
};

export default TimeframeDropdown;
