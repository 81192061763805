import React from 'react';
import { shape, arrayOf, bool, func, number, string, objectOf } from 'prop-types';
import { connect } from 'react-redux';

import AccessDeniedMessage from 'components/messages/AccessDeniedMessage';
import AddedKeywordsMessage from 'components/messages/AddedKeywordsMessage';
import AnnotationsMessage from 'components/messages/AnnotationsMessage';
import CreateTrackingMessage from 'components/messages/CreateTrackingMessage';
import DeleteConfirmationMessage from 'components/messages/DeleteConfirmationMessage';
import FailureMessage from 'components/messages/FailureMessage';
import LoggedOutMessage from 'components/messages/LoggedOutMessage';
import NeedToSelectKeywordMessage from 'components/messages/NeedToSelectKeywordMessage';
import NeedToSignInMessage from 'components/messages/NeedToSignInMessage';
import NewTrackingInfoMessage from 'components/messages/NewTrackingInfoMessage';
import NoConnectionMessage from 'components/messages/NoConnectionMessage';
import PricingMessage from 'components/messages/PricingMessage';
import TrackingAlreadyExistsMessage from 'components/messages/TrackingAlreadyExistsMessage';
import UnauthorizedMessage from 'components/messages/UnauthorizedMessage';
import ShortcutsMessage from 'components/messages/ShortcutsMessage';
import InvalidShareTokenMessage from 'components/messages/InvalidShareTokenMessage';
import EditTrackingMessage from 'components/messages/EditTrackingMessage';
import { ImportKwfListMessage } from 'components/messages/ImportKwfListMessage';

import {
    closeAccessDeniedMessage,
    closeAddedKeywordsMessage,
    closeAnnotationsMessage,
    closeCreateTrackingMessage,
    closeDeleteConfirmationMessage,
    closeFailureMessage,
    closeLoggedOutMessage,
    closeNeedToSelectKeywordMessage,
    closeNeedToSignInMessage,
    closeNewTrackingInfoMessage,
    closeNoConnectionMessage,
    closePricingMessage,
    closeShortcutsMessage,
    closeTrackingAlreadyExistsMessage,
    closeUnauthorizedMessage,
    confirmDeleteConfirmationMessage,
    requestedTrackingOpenAndFillAddKeywordsPanel,
    closeInvalidShareTokenMessage,
    closeEditTrackingMessage,
    setNewTrackingSelectedList,
    closeImportKwfListMessage,
    resetNewTrackingSelectedLists,
} from 'actions/uiActions';

import {
    accessDeniedMessageVisibilitySelector,
    addedKeywordsKeywordsSelector,
    addedKeywordsPostponedProcessingSelector,
    addedKeywordsVisibilitySelector,
    createTrackingDomainSelector,
    createTrackingFetchingSelector,
    createTrackingPlatformLabelSelector,
    createTrackingTemplateIdSelector,
    createTrackingVisibilitySelector,
    deleteConfirmationMessageResourceNameSelector,
    deleteConfirmationMessageResourceTypeSelector,
    deleteConfirmationMessageVisibilitySelector,
    failureMessageDetailsSelector,
    failureMessageHeaderSelector,
    failureMessageVisibilitySelector,
    loggedOutMessageVisibilitySelector,
    needToSelectKeywordMessageVisibilitySelector,
    needToSignInMessageVisibilitySelector,
    newTrackingInfoMessagePostponedProcessingSelector,
    newTrackingInfoMessageVisibilitySelector,
    noConnectionMessageVisibilitySelector,
    pricingMessageVisibilitySelector,
    shortcutsMessageVisibilitySelector,
    trackingAlreadyExistsKeywordsSelector,
    trackingAlreadyExistsTrackingIdSelector,
    trackingAlreadyExistsVisibilitySelector,
    trackingAnnotationDeletingIdSelector,
    trackingAnnotationUpdatingIdSelector,
    unauthorizedMessageVisibilitySelector,
    invalidShareTokenMessageVisibilitySelector,
    editTrackingMessageVisibilitySelector,
    EditTrackingMessageDataSelector,
    importKwfListMessageVisibilitySelector,
    importKwfListMessageImportTargetSelector,
    deleteConfirmationMessageResourceDetailsSelector,
    addedKeywordsEstimatedTimeProcessingSelector,
    newTrackingInfoMessageEstimatedTimeProcessingSelector,
} from 'selectors/uiSelectors';

import { trackedKeywordLimitSelector, userPlanTypeSelector } from 'selectors/userSelectors';

import {
    trackingDetailIdSelector,
    trackingDetailShareTokenSelector,
    groupedAndSortedTrackingsObjectSelector,
    sortedListDataSelector,
    listsFetchingSelector,
} from 'selectors/dataSelectors';

import {
    annotationsMessageVisibleDateSelector,
    annotationsMesssageDataSelector,
    trackingDetailAnnotationsFetchingCreateSelector,
    newTrackingSelectedListIdsSelector,
} from 'selectors/commonSelectors';

import {
    requestedCreateAnnotationAction,
    requestedDeleteAnnotationAction,
    requestedNewTrackingCloneAction,
    requestedUpdateAnnotationAction,
    requestedTrackingDomainUpdateAction,
    requestedListsAction,
    requestedNewTrackingListsKeywordsAction,
} from 'actions/dataActions';

import { requestedNavigationAction } from 'actions/routerActions';

import RouterService from 'services/RouterService';

import RoutePaths from 'constants/RoutePaths';
import NewTrackingCloneTypes from 'constants/NewTrackingCloneTypes';

import AnnotationType from 'types/AnnotationType';
import TrackingGroupWithStatsType from 'types/TrackingGroupWithStatsType';
import LocationType from 'types/LocationType';
import ListType from 'types/ListType';
import { IS_CUSTOM_DOMAIN } from 'constants/isCustomDomain';
import UpsellMessage from './UpsellMessage';
import WhiteLabelErrorMessage from './WhiteLabelErrorMessage';
import LookerStudioMessage from './LookerStudioMessage';

function MessageContainer(props) {
    const report = RouterService.isSame(props.currentRoute, RoutePaths.REPORT);
    const isCustomDomain = IS_CUSTOM_DOMAIN;

    if (isCustomDomain) {
        // I don't see any more modal interesting for white label report mode.
        const isVisible =
            props.accessDeniedVisible ||
            // props.needToSignInVisible ||
            props.invalidShareTokenVisible ||
            props.unauthorizedVisible;
        const onClose =
            props.onCloseAccessDeniedMessage ||
            // props.onCloseNeedToSignInMessage ||
            props.onCloseInvalidShareTokenMessage ||
            props.onCloseUnauthorizedMessage;

        return (
            <>
                <WhiteLabelErrorMessage onClose={onClose} visible={isVisible} />
                <NoConnectionMessage onClose={props.onCloseNoConnectionMessage} visible={props.noConnectionVisible} />
            </>
        );
    }

    return (
        <>
            <LookerStudioMessage />
            <AccessDeniedMessage visible={props.accessDeniedVisible} onClose={props.onCloseAccessDeniedMessage} />
            <AnnotationsMessage
                data={props.annotationsMesssageData}
                deletingId={props.annotationDeletingId}
                fetchingCreate={props.fetchingCreateAnnotation}
                onClose={props.onCloseAnnotationsMessage}
                onCreate={props.requestedCreateAnnotation}
                onDelete={props.requestedDeleteAnnotation}
                onUpdate={props.requestedUpdateAnnotation}
                report={report}
                updatingId={props.annotationUpdatingId}
                visibleDate={props.annotationsVisibleDate}
            />
            <NeedToSignInMessage visible={props.needToSignInVisible} onClose={props.onCloseNeedToSignInMessage} />
            <NeedToSelectKeywordMessage
                visible={props.needToSelectKeywordVisible}
                onClose={props.onCloseNeedToSelectKeywordMessage}
            />
            <NewTrackingInfoMessage
                keywordsLimit={props.keywordsLimit}
                onClose={props.onCloseNewTrackingInfoMessage}
                postponedProcessing={props.newTrackingInfoPostponedProcessing}
                estimatedTimeProcessing={props.newTrackingInfoEstimatedTimeProcessing}
                visible={props.newTrackingInfoVisible}
            />
            <FailureMessage
                details={props.failureDetails}
                header={props.failureHeader}
                onClose={props.onCloseFailureMessage}
                visible={props.failureVisible}
            />
            <PricingMessage
                onClose={props.onClosePricingMessage}
                userPlanType={props.userPlanType}
                visible={props.pricingVisible}
            />
            <DeleteConfirmationMessage
                onClose={props.onCloseDeleteConfirmationMessage}
                onConfirm={props.onConfirmDeleteConfirmationMessage}
                resourceName={props.deleteConfirmationResourceName}
                resourceDetails={props.deleteConfirmationResourceDetails}
                resourceType={props.deleteConfirmationResourceType}
                visible={props.deleteConfirmationVisible}
            />
            <CreateTrackingMessage
                domain={props.createTrackingDomain}
                fetching={props.createTrackingFetching}
                onClose={props.onCloseCreateTrackingMessage}
                onCreate={props.requestedCreateTracking}
                platformLabel={props.createTrackingPlatformLabel}
                templateTrackingId={props.createTrackingTemplateId}
                visible={props.createTrackingVisible}
            />
            <UpsellMessage />
            <AddedKeywordsMessage
                keywords={props.addedKeywordsKeywords}
                keywordsLimit={props.keywordsLimit}
                onClose={props.onCloseAddKeywordsMessage}
                postponedProcessing={props.addedKeywordsPostponedProcessing}
                visible={props.addedKeywordsVisible}
                estimatedTimeProcessing={props.addedKeywordsEstimatedTimeProcessing}
            />
            <TrackingAlreadyExistsMessage
                keywords={props.trackingAlreadyExistsKeywords}
                onAddKeywords={props.onOpenTrackingAndFillAddKeywordsPanel}
                onClose={props.onCloseTrackingAlreadyExistsMessage}
                trackingId={props.trackingAlreadyExistsTrackingId}
                visible={props.trackingAlreadyExistsVisible}
            />
            <NoConnectionMessage onClose={props.onCloseNoConnectionMessage} visible={props.noConnectionVisible} />
            <UnauthorizedMessage onClose={props.onCloseUnauthorizedMessage} visible={props.unauthorizedVisible} />
            <LoggedOutMessage onClose={props.onCloseLoggedOutMessage} visible={props.loggedOutVisible} />
            <ShortcutsMessage visible={props.shortcutsVisible} onClose={props.onCloseShortcutsMessage} />
            <InvalidShareTokenMessage
                visible={props.invalidShareTokenVisible}
                onClose={props.onCloseInvalidShareTokenMessage}
            />
            <EditTrackingMessage
                onClose={props.onCloseEditTrackingMessage}
                tracking={props.EditTrackingMessageData}
                onSubmit={props.requestedTrackingDomainUpdate}
                visible={props.editTrackingVisible}
                trackingGroups={props.trackingGroups}
            />
            <ImportKwfListMessage
                lists={props.lists}
                listsFetching={props.listsFetching}
                onClose={props.onCloseImportKwfListMessage}
                onImportListsClick={props.onImportLists}
                onListSelect={props.onListSelect}
                requestLists={props.requestLists}
                selectedListIds={props.selectedListIds}
                visible={props.importKwfListVisible}
                onResetNewTrackingSelectedLists={props.onResetNewTrackingSelectedLists}
                importTarget={props.importKwfListMessageImportTarget}
            />
        </>
    );
}

MessageContainer.propTypes = {
    accessDeniedVisible: bool.isRequired,
    addedKeywordsKeywords: arrayOf(string),
    addedKeywordsPostponedProcessing: bool.isRequired,
    addedKeywordsEstimatedTimeProcessing: number,
    addedKeywordsVisible: bool.isRequired,
    annotationDeletingId: string,
    annotationUpdatingId: string,
    annotationsMesssageData: arrayOf(AnnotationType).isRequired,
    annotationsVisibleDate: string,
    createTrackingDomain: string,
    createTrackingFetching: bool.isRequired,
    createTrackingPlatformLabel: string.isRequired,
    createTrackingTemplateId: string,
    createTrackingVisible: bool.isRequired,
    currentRoute: string.isRequired,
    deleteConfirmationResourceName: string.isRequired,
    deleteConfirmationResourceDetails: shape({
        location: shape({
            label: string.isRequired,
        }),
        domain: string.isRequired,
        platform: string.isRequired,
    }),
    deleteConfirmationResourceType: string.isRequired,
    deleteConfirmationVisible: bool.isRequired,
    failureDetails: string,
    failureHeader: string,
    failureVisible: bool.isRequired,
    fetchingCreateAnnotation: bool.isRequired,
    keywordsLimit: number.isRequired,
    loggedOutVisible: bool.isRequired,
    needToSelectKeywordVisible: bool.isRequired,
    needToSignInVisible: bool.isRequired,
    newTrackingInfoPostponedProcessing: bool.isRequired,
    newTrackingInfoEstimatedTimeProcessing: number,
    newTrackingInfoVisible: bool.isRequired,
    noConnectionVisible: bool.isRequired,
    onCloseAccessDeniedMessage: func.isRequired,
    onCloseAddKeywordsMessage: func.isRequired,
    onCloseAnnotationsMessage: func.isRequired,
    onCloseCreateTrackingMessage: func.isRequired,
    onCloseDeleteConfirmationMessage: func.isRequired,
    onCloseFailureMessage: func.isRequired,
    onCloseLoggedOutMessage: func.isRequired,
    onCloseNeedToSelectKeywordMessage: func.isRequired,
    onCloseNeedToSignInMessage: func.isRequired,
    onCloseNewTrackingInfoMessage: func.isRequired,
    onCloseNoConnectionMessage: func.isRequired,
    onClosePricingMessage: func.isRequired,
    onCloseShortcutsMessage: func.isRequired,
    onCloseTrackingAlreadyExistsMessage: func.isRequired,
    onCloseUnauthorizedMessage: func.isRequired,
    onConfirmDeleteConfirmationMessage: func.isRequired,
    onOpenTrackingAndFillAddKeywordsPanel: func.isRequired,
    pricingVisible: bool.isRequired,
    requestedCreateAnnotation: func.isRequired,
    requestedCreateTracking: func.isRequired,
    requestedDeleteAnnotation: func.isRequired,
    requestedUpdateAnnotation: func.isRequired,
    shortcutsVisible: bool.isRequired,
    trackingAlreadyExistsKeywords: arrayOf(string).isRequired,
    trackingAlreadyExistsTrackingId: string,
    trackingAlreadyExistsVisible: bool.isRequired,
    unauthorizedVisible: bool.isRequired,
    userPlanType: string.isRequired,
    onCloseInvalidShareTokenMessage: func.isRequired,
    invalidShareTokenVisible: bool.isRequired,
    onCloseEditTrackingMessage: func.isRequired,
    editTrackingVisible: bool.isRequired,
    requestedTrackingDomainUpdate: func.isRequired,
    EditTrackingMessageData: shape({
        domain: string,
        id: string,
        location: LocationType,
    }).isRequired,
    trackingGroups: objectOf(TrackingGroupWithStatsType).isRequired,
    lists: arrayOf(ListType).isRequired,
    listsFetching: bool.isRequired,
    onCloseImportKwfListMessage: func.isRequired,
    onImportLists: func.isRequired,
    requestLists: func.isRequired,
    onListSelect: func.isRequired,
    selectedListIds: arrayOf(string).isRequired,
    importKwfListVisible: bool.isRequired,
    onResetNewTrackingSelectedLists: func.isRequired,
    importKwfListMessageImportTarget: string,
};

const mapStateToProps = state => ({
    accessDeniedVisible: accessDeniedMessageVisibilitySelector(state),
    addedKeywordsKeywords: addedKeywordsKeywordsSelector(state),
    addedKeywordsPostponedProcessing: addedKeywordsPostponedProcessingSelector(state),
    addedKeywordsEstimatedTimeProcessing: addedKeywordsEstimatedTimeProcessingSelector(state),
    addedKeywordsVisible: addedKeywordsVisibilitySelector(state),
    annotationDeletingId: trackingAnnotationDeletingIdSelector(state),
    annotationUpdatingId: trackingAnnotationUpdatingIdSelector(state),
    annotationsMesssageData: annotationsMesssageDataSelector(state),
    annotationsVisibleDate: annotationsMessageVisibleDateSelector(state),
    createTrackingDomain: createTrackingDomainSelector(state),
    createTrackingFetching: createTrackingFetchingSelector(state),
    createTrackingPlatformLabel: createTrackingPlatformLabelSelector(state),
    createTrackingTemplateId: createTrackingTemplateIdSelector(state),
    createTrackingVisible: createTrackingVisibilitySelector(state),
    deleteConfirmationResourceName: deleteConfirmationMessageResourceNameSelector(state),
    deleteConfirmationResourceDetails: deleteConfirmationMessageResourceDetailsSelector(state),
    deleteConfirmationResourceType: deleteConfirmationMessageResourceTypeSelector(state),
    deleteConfirmationVisible: deleteConfirmationMessageVisibilitySelector(state),
    failureDetails: failureMessageDetailsSelector(state),
    failureHeader: failureMessageHeaderSelector(state),
    failureVisible: failureMessageVisibilitySelector(state),
    fetchingCreateAnnotation: trackingDetailAnnotationsFetchingCreateSelector(state),
    keywordsLimit: trackedKeywordLimitSelector(state),
    loggedOutVisible: loggedOutMessageVisibilitySelector(state),
    needToSelectKeywordVisible: needToSelectKeywordMessageVisibilitySelector(state),
    needToSignInVisible: needToSignInMessageVisibilitySelector(state),
    newTrackingInfoPostponedProcessing: newTrackingInfoMessagePostponedProcessingSelector(state),
    newTrackingInfoEstimatedTimeProcessing: newTrackingInfoMessageEstimatedTimeProcessingSelector(state),
    newTrackingInfoVisible: newTrackingInfoMessageVisibilitySelector(state),
    noConnectionVisible: noConnectionMessageVisibilitySelector(state),
    pricingVisible: pricingMessageVisibilitySelector(state),
    shortcutsVisible: shortcutsMessageVisibilitySelector(state),
    trackingAlreadyExistsKeywords: trackingAlreadyExistsKeywordsSelector(state),
    trackingAlreadyExistsTrackingId: trackingAlreadyExistsTrackingIdSelector(state),
    trackingAlreadyExistsVisible: trackingAlreadyExistsVisibilitySelector(state),
    trackingDetailId: trackingDetailIdSelector(state),
    trackingDetailShareToken: trackingDetailShareTokenSelector(state),
    unauthorizedVisible: unauthorizedMessageVisibilitySelector(state),
    userPlanType: userPlanTypeSelector(state),
    invalidShareTokenVisible: invalidShareTokenMessageVisibilitySelector(state),
    editTrackingVisible: editTrackingMessageVisibilitySelector(state),
    EditTrackingMessageData: EditTrackingMessageDataSelector(state),
    trackingGroups: groupedAndSortedTrackingsObjectSelector(state),
    lists: sortedListDataSelector(state),
    listsFetching: listsFetchingSelector(state),
    selectedListIds: newTrackingSelectedListIdsSelector(state),
    importKwfListVisible: importKwfListMessageVisibilitySelector(state),
    importKwfListMessageImportTarget: importKwfListMessageImportTargetSelector(state),
});

const mapDispatchToProps = dispatch => ({
    onOpenTrackingAndFillAddKeywordsPanel({ trackingId, keywords }) {
        dispatch(
            requestedTrackingOpenAndFillAddKeywordsPanel({
                trackingId,
                keywords,
            }),
        );
    },
    onConfirmDeleteConfirmationMessage() {
        dispatch(confirmDeleteConfirmationMessage());
    },
    onCloseAddKeywordsMessage() {
        dispatch(closeAddedKeywordsMessage());
    },
    onCloseCreateTrackingMessage() {
        dispatch(closeCreateTrackingMessage());
    },
    onCloseDeleteConfirmationMessage() {
        dispatch(closeDeleteConfirmationMessage());
    },
    onCloseAccessDeniedMessage() {
        dispatch(closeAccessDeniedMessage());
    },
    onCloseAnnotationsMessage() {
        dispatch(closeAnnotationsMessage());
    },
    onCloseFailureMessage() {
        dispatch(closeFailureMessage());
    },
    onCloseLoggedOutMessage() {
        dispatch(closeLoggedOutMessage());
    },
    onCloseNeedToSignInMessage() {
        dispatch(closeNeedToSignInMessage());
    },
    onCloseNoConnectionMessage() {
        dispatch(closeNoConnectionMessage());
    },
    onCloseNewTrackingInfoMessage() {
        dispatch(closeNewTrackingInfoMessage());
    },
    onCloseNeedToSelectKeywordMessage() {
        dispatch(closeNeedToSelectKeywordMessage());
    },
    onClosePricingMessage() {
        dispatch(closePricingMessage());
    },
    onCloseShortcutsMessage() {
        dispatch(closeShortcutsMessage());
    },
    onCloseTrackingAlreadyExistsMessage() {
        dispatch(closeTrackingAlreadyExistsMessage());
    },
    onCloseUnauthorizedMessage() {
        dispatch(closeUnauthorizedMessage());
    },
    requestedCreateTracking(templateTrackingId) {
        dispatch(
            requestedNewTrackingCloneAction({
                templateTrackingId,
                cloneType: NewTrackingCloneTypes.OPPOSITE_PLATFORM,
            }),
        );
    },
    requestedNavigationToNewTracking() {
        dispatch(requestedNavigationAction(RoutePaths.NEW, {}));
    },
    requestedNavigationToTrackings() {
        dispatch(requestedNavigationAction(RoutePaths.TRACKINGS, {}));
    },
    requestedCreateAnnotation({ annotationText, date, nonShareable }) {
        dispatch(requestedCreateAnnotationAction({ annotationText, date, nonShareable }));
    },
    requestedDeleteAnnotation(annotationId) {
        dispatch(requestedDeleteAnnotationAction(annotationId));
    },
    requestedUpdateAnnotation({ annotationId, annotationText, nonShareable }) {
        dispatch(requestedUpdateAnnotationAction({ annotationId, annotationText, nonShareable }));
    },
    onCloseInvalidShareTokenMessage() {
        dispatch(closeInvalidShareTokenMessage());
    },
    onCloseEditTrackingMessage() {
        dispatch(closeEditTrackingMessage());
    },
    requestedTrackingDomainUpdate(payload) {
        dispatch(requestedTrackingDomainUpdateAction(payload));
    },
    onImportLists() {
        dispatch(requestedNewTrackingListsKeywordsAction());
    },
    onListSelect(listId, selected) {
        dispatch(setNewTrackingSelectedList(listId, selected));
    },
    onCloseImportKwfListMessage() {
        dispatch(closeImportKwfListMessage());
    },
    requestLists() {
        dispatch(requestedListsAction());
    },
    onResetNewTrackingSelectedLists() {
        dispatch(resetNewTrackingSelectedLists());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageContainer);
