import moment from 'moment';
import isNil from 'ramda/src/isNil';

import DateFormatService from 'services/DateFormatService';
import TimeframePresets, { MAXIMUM_DAYS } from 'constants/TimeframePresets';
import { REFERENCE_POINT, POINT_1, POINT_2, POINT_3 } from 'constants/TimeframePoints';

class TimeframeService {
    // Translates TimeframePresets type and/or `from, to` moments
    // to `from, to` ISO seconds timestamps
    static translateToISO({ type, from, to }) {
        return {
            from: DateFormatService.formatISO(moment(from).startOf('day')),
            to: DateFormatService.formatISO(moment(to).endOf('day')),
            type,
        };
    }

    // Translates to moment timeframe object
    static translate({ createdAt, from, to }) {
        const today = moment().endOf('day');
        const yesterday = moment(today).subtract(1, 'day');
        const fromMoment = moment(from).startOf('day');
        const toMoment = moment(to).endOf('day');

        if (fromMoment.isSame(today, 'day') && toMoment.isSame(today, 'day')) {
            return {
                from: today,
                to: today,
                type: TimeframePresets.TODAY,
            };
        } else if (
            fromMoment.isSame(moment.unix(createdAt), 'day') &&
            (toMoment.isSame(yesterday, 'day') || toMoment.isSame(today, 'day'))
        ) {
            return {
                from: fromMoment,
                to: toMoment,
                type: TimeframePresets.ENTIRE_HISTORY,
            };
        } else if (toMoment.isSame(yesterday, 'day')) {
            // to is yesterday
            let type = TimeframePresets.CUSTOM;

            if (fromMoment.isSame(moment().subtract(7, 'days'), 'day')) {
                type = TimeframePresets.LAST_7_DAYS;
            } else if (fromMoment.isSame(moment().subtract(30, 'days'), 'day')) {
                type = TimeframePresets.LAST_30_DAYS;
            } else if (fromMoment.isSame(moment().subtract(365, 'days'), 'day')) {
                type = TimeframePresets.LAST_365_DAYS;
            }

            return {
                from: fromMoment,
                to: toMoment,
                type,
            };
        } else if (toMoment.isSame(today, 'day')) {
            let type = TimeframePresets.CUSTOM;

            if (fromMoment.isSame(moment().startOf('month'), 'day')) {
                type = TimeframePresets.THIS_MONTH;
            } else if (fromMoment.isSame(moment().startOf('week').add(1, 'day'), 'day')) {
                type = TimeframePresets.THIS_WEEK;
            }

            return {
                from: fromMoment,
                to: toMoment,
                type,
            };
        } else if (
            toMoment.isSame(moment().startOf('week'), 'day') &&
            fromMoment.isSame(moment().startOf('week').subtract(1, 'day').startOf('week').add(1, 'day'), 'day')
        ) {
            return {
                from: fromMoment,
                to: toMoment,
                type: TimeframePresets.LAST_WEEK,
            };
        } else if (
            toMoment.isSame(moment().startOf('month').subtract(1, 'day'), 'day') &&
            fromMoment.isSame(moment().startOf('month').subtract(1, 'day').startOf('month'), 'day')
        ) {
            return {
                from: fromMoment,
                to: toMoment,
                type: TimeframePresets.LAST_MONTH,
            };
        } else {
            return {
                from: fromMoment,
                to: toMoment,
                type: TimeframePresets.CUSTOM,
            };
        }
    }

    static createFromType({ createdAt, type }) {
        const today = moment().endOf('day');
        const dateFromCreated = moment.unix(createdAt).startOf('day'); // Hodnota dateFromCreated z createdAt

        if (type === TimeframePresets.TODAY) {
            const from = moment.max(today, dateFromCreated);
            return {
                from,
                to: today,
                type: TimeframePresets.TODAY,
            };
        } else if (type === TimeframePresets.ENTIRE_HISTORY) {
            const yesterday = moment(today).subtract(1, 'day');
            const maxHistoryDate = moment(today).subtract(MAXIMUM_DAYS, 'day');
            const dateFrom = moment.max(dateFromCreated, maxHistoryDate); // Vybere nejmladší datum mezi maxHistoryDate a dateFromCreated
            const dateTo = today;

            return {
                from: dateFrom,
                to: dateFrom.isSame(today, 'day') || dateFrom.isSame(yesterday, 'day') ? dateTo : yesterday,
                type: TimeframePresets.ENTIRE_HISTORY,
            };
        } else if (type === TimeframePresets.THIS_MONTH) {
            const from = moment.max(moment().startOf('month'), dateFromCreated);
            return {
                from,
                to: today,
                type: TimeframePresets.THIS_MONTH,
            };
        } else if (type === TimeframePresets.LAST_MONTH) {
            const from = moment.max(moment().startOf('month').subtract(1, 'month').startOf('month'), dateFromCreated);
            const to = moment().startOf('month').subtract(1, 'day');
            return {
                from,
                to,
                type: TimeframePresets.LAST_MONTH,
            };
        } else if (type === TimeframePresets.THIS_WEEK) {
            const from = moment.max(moment().startOf('week').add(1, 'day'), dateFromCreated);
            return {
                from,
                to: today,
                type: TimeframePresets.THIS_WEEK,
            };
        } else if (type === TimeframePresets.LAST_WEEK) {
            const from = moment.max(
                moment().startOf('week').subtract(1, 'day').startOf('week').add(1, 'day'),
                dateFromCreated,
            );
            const to = moment().startOf('week');
            return {
                from,
                to,
                type: TimeframePresets.LAST_WEEK,
            };
        } else {
            let days;
            switch (type) {
                case TimeframePresets.LAST_7_DAYS:
                    days = 7;
                    break;
                case TimeframePresets.LAST_30_DAYS:
                    days = 30;
                    break;
                case TimeframePresets.LAST_365_DAYS:
                    days = 365;
                    break;
                default:
                    days = 30;
            }
            const from = moment.max(moment().subtract(days, 'days').startOf('day'), dateFromCreated);
            const to = moment().subtract(1, 'days').endOf('day');

            return {
                from,
                to,
                type,
            };
        }
    }

    static getDefault(trackingCreatedAt) {
        const now = moment();
        const today = moment(now).endOf('day');
        const yesterday = moment(today).subtract(1, 'day');

        const defaultTimeframe = {
            from: moment(now).subtract(30, 'days').startOf('day'),
            to: yesterday,
            type: TimeframePresets.LAST_30_DAYS,
        };

        if (!isNil(trackingCreatedAt)) {
            const date = moment.unix(trackingCreatedAt).startOf('day');

            if (date.isSame(today, 'day') || date.isSame(yesterday, 'day')) {
                return {
                    from: date,
                    to: date,
                    type: TimeframePresets.ENTIRE_HISTORY,
                };
            } else if (date.isAfter(moment(now).subtract(30, 'days'))) {
                return TimeframeService.translate({
                    createdAt: trackingCreatedAt,
                    from: date,
                    to: yesterday,
                });
            } else {
                return defaultTimeframe;
            }
        } else {
            return defaultTimeframe;
        }
    }

    static getFirstAvailableDay(trackingCreatedAt) {
        if (!isNil(trackingCreatedAt)) {
            return moment.unix(trackingCreatedAt).startOf('day');
        } else {
            return null;
        }
    }

    static isPresetAvailable(trackingCreatedAt, preset) {
        if (!isNil(trackingCreatedAt) && !isNil(preset)) {
            const refDay = this.getFirstAvailableDay(trackingCreatedAt);

            switch (preset) {
                case TimeframePresets.TODAY:
                case TimeframePresets.ENTIRE_HISTORY: {
                    return true;
                }
                case TimeframePresets.THIS_MONTH: {
                    return refDay.isSameOrBefore(moment().endOf('month'), 'day');
                }
                case TimeframePresets.LAST_MONTH: {
                    return refDay.isSameOrBefore(moment().endOf('month').subtract(1, 'month'), 'day');
                }
                case TimeframePresets.THIS_WEEK: {
                    return refDay.isSameOrBefore(moment().endOf('week'), 'day');
                }
                case TimeframePresets.LAST_WEEK: {
                    return refDay.isSameOrBefore(moment().endOf('week').subtract(7, 'days'), 'day');
                }
                case TimeframePresets.LAST_7_DAYS: {
                    return refDay.isSameOrBefore(moment().subtract(7, 'days'), 'day');
                }
                case TimeframePresets.LAST_30_DAYS: {
                    return refDay.isSameOrBefore(moment().subtract(30, 'days'), 'day');
                }
                case TimeframePresets.LAST_365_DAYS: {
                    return refDay.isSameOrBefore(moment().subtract(365, 'days'), 'day');
                }
                default: {
                    return false;
                }
            }
        } else {
            return false;
        }
    }

    static splitToPoints(timeframe) {
        if (!isNil(timeframe.from) && !isNil(timeframe.to)) {
            const interval = Math.round((timeframe.to.unix() - timeframe.from.unix()) / 3);

            return {
                [REFERENCE_POINT]: timeframe.to,
                [POINT_1]: moment.unix(timeframe.to.unix() - interval).endOf('day'),
                [POINT_2]: moment.unix(timeframe.to.unix() - 2 * interval).endOf('day'),
                [POINT_3]: timeframe.from.endOf('day'),
            };
        } else {
            return {
                [REFERENCE_POINT]: null,
                [POINT_1]: null,
                [POINT_2]: null,
                [POINT_3]: null,
            };
        }
    }
}

export default TimeframeService;
