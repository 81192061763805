import { isNil, pathOr, path } from 'ramda';

import Colors from 'mangools-commons/lib/constants/Colors';

const parseCreatedAt = rawCreatedAt => {
    if (rawCreatedAt) {
        return new Date(rawCreatedAt).getTime();
    } else {
        return null;
    }
};

export const parseSettings = user => {
    return {
        whiteLabel: {
            logo: pathOr('', ['settings', 'white_label', 'logo'], user),
            name: pathOr('', ['settings', 'white_label', 'name'], user),
            website: pathOr('', ['settings', 'white_label', 'website'], user),
            customDomain: pathOr('', ['settings', 'white_label', 'customDomain'], user),
            email: pathOr('', ['settings', 'white_label', 'email'], user),
            accentColor: !isNil(path(['settings', 'white_label', 'accentColor'], user))
                ? path(['settings', 'white_label', 'accentColor'], user)
                : Colors.SERPWATCHER,
            enabled: pathOr(false, ['settings', 'white_label', 'enabled'], user),
        },
        hideVisibilityIndex: pathOr(false, ['settings', 'sw_hide_visibility_index'], user),
        hidePerformanceIndex: pathOr(false, ['settings', 'sw_hide_performance_index'], user),
        hideEstimatedVisits: pathOr(false, ['settings', 'sw_hide_estimated_visits'], user),
    };
};

export const parse = rawData => {
    if (isNil(rawData.user)) {
        return {
            accessToken: null,
            createdAt: null,
            email: null,
            id: null,
            plan: null,
        };
    } else {
        return {
            accessToken: rawData.user.api_key,
            isSubUser: rawData.isSubUser,
            createdAt: parseCreatedAt(rawData.user.created_at),
            email: rawData.user.email,
            id: rawData.user.id,
            plan: rawData.user.plan,
            settings: parseSettings(rawData.user),
        };
    }
};
