import React, { Component } from 'react';
import { bool, func } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

import DarkModeToggle from 'components/other/DarkModeToggle';
import HelpDropdown from 'components/layout/navbar/HelpDropdown';
import RoutePaths from 'constants/RoutePaths';
import { LookerStudioButton } from '../../other/LookerStudioButton';

class MenuRight extends Component {
    constructor() {
        super();

        this.handleDocumentClick = this.handleDocumentClick.bind(this);
        this.handleHelpDropdownClick = this.handleHelpDropdownClick.bind(this);

        // Dropdown containers
        this.helpDropdownContainer = null;
    }

    componentDidMount() {
        window.APP_ROOT.addEventListener('click', this.handleDocumentClick);
    }

    componentWillUnmount() {
        window.APP_ROOT.removeEventListener('click', this.handleDocumentClick);
    }

    handleDocumentClick(e) {
        if (
            this.props.helpDropdownVisible &&
            this.helpDropdownContainer &&
            !this.helpDropdownContainer.contains(e.target)
        ) {
            this.props.onCloseHelpDropdown();
        }
    }

    handleHelpDropdownClick() {
        if (this.props.helpDropdownVisible === true) {
            this.props.onCloseHelpDropdown();
        } else {
            this.props.onShowHelpDropdown();
        }
    }

    render() {
        return (
            <nav className="uk-margin-remove uk-padding-remove uk-flex uk-flex-middle uk-hidden-small">
                <ul className="mg-nav uk-flex-inline">
                    {[RoutePaths.TRACKINGS, RoutePaths.TRACKING].includes(this.props.currentRoute) ? (
                        <li>
                            <LookerStudioButton />
                        </li>
                    ) : null}
                    <li>
                        <DarkModeToggle />
                    </li>
                    <li className="uk-hidden-touch uk-hidden-medium">
                        <button
                            className="uk-hidden-touch uk-hidden-small"
                            onClick={this.props.onShowShortcutsMessage}
                            type="button"
                        >
                            <FontAwesomeIcon icon="keyboard" aria-label="Keyboard shortcuts" />
                        </button>
                    </li>
                    <li
                        className={classnames('uk-hidden-small', 'uk-position-relative', {
                            'uk-active': this.props.helpDropdownVisible === true,
                        })}
                        ref={c => (this.helpDropdownContainer = c)}
                    >
                        <button onClick={this.handleHelpDropdownClick} type="button">
                            Help
                            <FontAwesomeIcon icon={this.props.helpDropdownVisible ? 'chevron-up' : 'chevron-down'} />
                        </button>
                        <HelpDropdown visible={this.props.helpDropdownVisible} />
                    </li>
                </ul>
            </nav>
        );
    }
}

MenuRight.propTypes = {
    helpDropdownVisible: bool.isRequired,
    onCloseHelpDropdown: func.isRequired,
    onShowHelpDropdown: func.isRequired,
    onShowShortcutsMessage: func.isRequired,
};

export default MenuRight;
