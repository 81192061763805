import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { lookerStudioMessageVisibilitySelector } from 'selectors/uiSelectors';
import MessageHolder from 'components/messages/MessageHolder';
import { closeLookerStudioMessage } from '../../actions/uiActions';
import Preloader from '../other/Preloader';
import Emoji from '../other/Emoji';
import { gtmTrack } from '../../actions/analyticsActions';
import { analyticsActions, analyticsEvents } from '../../constants/analytics';

function LookerStudioMessage() {
    const dispatch = useDispatch();
    const isVisible = useSelector(state => lookerStudioMessageVisibilitySelector(state));

    const handleClickVote = () => {
        dispatch(
            gtmTrack({
                action: analyticsActions.CLICK,
                event: analyticsEvents.LOOKER_STUDIO_MESSAGE_LINK,
            }),
        );
    };

    if (isVisible) {
        return (
            <MessageHolder
                onClose={() => {
                    dispatch(closeLookerStudioMessage());
                    dispatch(
                        gtmTrack({
                            action: analyticsActions.CLOSE,
                            event: analyticsEvents.LOOKER_STUDIO_MESSAGE,
                        }),
                    );
                }}
            >
                <div className="mg-modal-header is-mngls uk-padding-remove" />
                <div className="uk-flex uk-flex-wrap">
                    <div className="uk-width-large-1-1   uk-flex uk-flex-middle uk-flex-center">
                        <div className="mg-modal-content">
                            <div className="uk-flex uk-flex-center mg-margin-t-30">
                                <Preloader text="" />
                            </div>
                            <h3 className="font-30 uk-text-bold mg-margin-t-30">
                                Do you need a
                                <br />
                                Looker Studio connector?
                            </h3>
                            <p className="font-16">
                                Everything depends on you! Click the button below to upvote the idea in Nolt, and we
                                will build it!
                            </p>
                            <div className="mg-margin-t-30">
                                <a
                                    className="mg-btn is-green is-gradient"
                                    href="https://mangools.nolt.io/198?ref=msg-header-btn"
                                    target='_blank'
                                    onClick={handleClickVote}
                                >
                                    <strong>Upvote for the Looker Studio connector</strong>
                                </a>
                                <div className="font-14 color-grey mg-margin-t-10">
                                    5 seconds and you&apos;re good to go, we promise&nbsp;
                                    <Emoji symbol="🤞" label="crossed-fingers" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MessageHolder>
        );
    } else {
        return null;
    }
}

export default LookerStudioMessage;
