import React, { useCallback, useState } from 'react';
import { withVisibilityLogic } from 'mangools-react-components/src';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { settingsSelector } from 'selectors/userSelectors';
import { UserSettingsState } from 'types/state/userReducer';
import { closeCustomizeResultsPanel } from '../../actions/uiActions';
import { requestedCustomizationSettingsUpdateAction } from '../../actions/userActions';

type CustomizationSettings = Pick<UserSettingsState , 'hideVisibilityIndex' | 'hidePerformanceIndex' | 'hideEstimatedVisits'>;

const CustomizeResultsPanel = () => {
    const dispatch = useDispatch();
    const { hideVisibilityIndex, hidePerformanceIndex, hideEstimatedVisits } = useSelector(settingsSelector);

    const [settings, setSettings] = useState<CustomizationSettings>({
        hideVisibilityIndex,
        hidePerformanceIndex,
        hideEstimatedVisits
    });

    const handleClose = useCallback(() => {
        dispatch(closeCustomizeResultsPanel());
    }, [dispatch]);

    const handleUpdateSettings = (updatedSettings: Partial<CustomizationSettings>) => {
        setSettings(prevState => ({
            ...prevState,
            ...updatedSettings,
        }));
    };

    const handleSave = async () => {
        dispatch(requestedCustomizationSettingsUpdateAction(settings));
        dispatch(closeCustomizeResultsPanel());
    };

    return (
        <aside className="mg-panel color-black">
            <div className="mg-panel-title mg-padding-30 mg-border-b">
                <h1 className="uk-margin-remove font-24 mg-truncate">
                    <FontAwesomeIcon icon="edit" aria-hidden="true" className="mg-margin-r-10" />
                    <strong>Customization settings</strong>
                </h1>
                <button
                    aria-label="Close panel"
                    className="mg-close"
                    onClick={handleClose}
                    type="button"
                >
                    <FontAwesomeIcon icon="times" />
                </button>
            </div>
            <div className="mg-padding-30 customization-panel-items">
                <label
                    htmlFor="hideSearchVolume"
                    onClick={() => handleUpdateSettings({ hideVisibilityIndex: !settings.hideVisibilityIndex })}
                >
                    <input
                        type="checkbox"
                        name="hideVisibilityIndex"
                        checked={settings.hideVisibilityIndex}
                    />
                    Hide visibility index
                </label>
                <label
                    htmlFor="hideSearchVolume"
                    onClick={() => handleUpdateSettings({ hidePerformanceIndex: !settings.hidePerformanceIndex })}
                >
                    <input
                        type="checkbox"
                        name="hidePerformanceIndex"
                        checked={settings.hidePerformanceIndex}
                    />
                    Hide performance index
                </label>
                <label
                    htmlFor="hideEstimatedVisits"
                    onClick={() => handleUpdateSettings({ hideEstimatedVisits: !settings.hideEstimatedVisits })}
                >
                    <input
                        type="checkbox"
                        name="hideEstimatedVisits"
                        checked={settings.hideEstimatedVisits}
                    />
                    Hide estimated visits
                </label>
                <button className="mg-btn is-green uk-width-1-1 is-small" type="button" onClick={handleSave}>
                    Save settings
                </button>
            </div>
        </aside>
    );
};

export default withVisibilityLogic(CustomizeResultsPanel);
