export default {
    NONE: 'NONE',
    CUSTOM: 'CUSTOM',
    ENTIRE_HISTORY: 'ENTIRE_HISTORY',
    TODAY: 'TODAY',
    THIS_MONTH: 'THIS_MONTH',
    LAST_MONTH: 'LAST_MONTH',
    THIS_WEEK: 'THIS_WEEK',
    LAST_WEEK: 'LAST_WEEK',
    LAST_7_DAYS: 'LAST_7_DAYS',
    LAST_30_DAYS: 'LAST_30_DAYS',
    LAST_365_DAYS: 'LAST_365_DAYS',
};

export const MINIMUM_DAYS = 1;
export const MAXIMUM_DAYS = 2 * 365;
