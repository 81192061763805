export const analyticsActions = {
    SHOW: 'show',
    CLOSE: 'close',
    CONFIRM: 'confirm',
    SELECT: 'select',
    LOGIN: 'login',
    REGISTER: 'register',
    NEXT: 'next',
    ADD_ONE: 'add_one',
    ADD_ALL: 'add_all',
    CLICK: 'click',
    LOG: 'log',
    HIDDEN: 'hidden',
};

export const analyticsEvents = {
    OUT_OF_LIMITS_MESSAGE: 'out_of_limits_message',
    LOGIN_MESSAGE: 'login_message',
    THEME_TOGGLE: 'theme_toggle',
    SUGGESTED_KEYWORDS_BANNER: 'new_tracking_suggested_kws_banner',

    NEW_TRACKING_STEP: 'new_tracking_step',
    IMPORT_KWF_LIST: 'new_tracking_import_kwf_list',
    ADD_SUGGESTED_KWS: 'new_tracking_add_suggested_kws',

    NEW_TRACKING_ADVANCED_SETTINGS: 'new_tracking_advanced_settings',
    TRACKING_GMB_LISTING: 'tracking_gmb_listing',

    NEED_MORE_KEYWORDS: 'need_more_keywords',
    NEED_MORE_KEYWORDS_CHAT_MSG: 'need_more_keywords_chat_msg',

    UPSELL_MODAL_LOCATIONS: 'upsell_modal_locations',

    ENABLE_GROUP_BY_DOMAN_FEATURE: 'sw_group_by_domain_on',
    DISABLE_GROUP_BY_DOMAN_FEATURE: 'sw_group_by_domain_off',

    LOOKER_STUDIO_MESSAGE: 'looker_studio_message',
    LOOKER_STUDIO_MESSAGE_LINK: 'looker_studio_message_link',
};

export const analyticsSources = {
    STANDARD: 'standard',
    KW_LIST: 'kw_list',
}